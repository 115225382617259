var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"address-form",attrs:{"vid":"address-form","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"grid grid-cols-12 gap-x-6 gap-y-7 mt-9 px-4 pb-8",attrs:{"data-test":"profile__address-modal"}},[_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Art der Adresse","rules":"required","slim":"","immediate":"","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{ref:"address-contacttype",attrs:{"id":"address-contacttype","data-test":"profile__address-edit-type","options":_vm.ADDRESS_TYPES,"label":"Adresstyp","option-label":"label","track-by":"key","errors":errors,"required":"","mapped":""},model:{value:(_vm.address.contactType),callback:function ($$v) {_vm.$set(_vm.address, "contactType", $$v)},expression:"address.contactType"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-full flex space-x-2"},[_c('validation-provider',{attrs:{"name":"Adresszusatz","rules":"max:200","slim":"","skip-if-empty":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"address-additional","data-test":"profile__address-edit-additional","label":"Adresszusatz (optional)","wide":"","errors":errors},model:{value:(_vm.address.addressAdditional),callback:function ($$v) {_vm.$set(_vm.address, "addressAdditional", $$v)},expression:"address.addressAdditional"}})]}}],null,true)}),_c('e-tooltip',{staticClass:"items-center justify-center text-cta",attrs:{"content":"Stockwerk, Gebäudeteil, oder ähnliches","placement":"left-center"}},[_c('e-icon',{attrs:{"icon":"mdi-information-outline","size":"2xl"}})],1)],1),_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{attrs:{"name":"Straße, Hausnummer","rules":"required|max:200","slim":"","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"address-street","data-test":"profile__address-edit-street","label":"Straße, Hausnummer","wide":"","required":"","errors":errors},model:{value:(_vm.address.streetAddress),callback:function ($$v) {_vm.$set(_vm.address, "streetAddress", $$v)},expression:"address.streetAddress"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-4"},[_c('validation-provider',{attrs:{"name":"Postleitzahl","rules":"required|postalCode|max:15","slim":"","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"address-postalcode","data-test":"profile__address-edit-postalcode","label":"Postleitzahl","wide":"","required":"","errors":errors},model:{value:(_vm.address.postalCode),callback:function ($$v) {_vm.$set(_vm.address, "postalCode", $$v)},expression:"address.postalCode"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-8"},[_c('validation-provider',{attrs:{"name":"Ort","rules":"required|max:50","slim":"","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"address-locality","data-test":"profile__address-edit-locality","label":"Ort","wide":"","required":"","errors":errors},model:{value:(_vm.address.addressLocality),callback:function ($$v) {_vm.$set(_vm.address, "addressLocality", $$v)},expression:"address.addressLocality"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{attrs:{"name":"Land","rules":"required","slim":"","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"country","data-test":"profile__address-edit-country","searchable":"","label":"Land","options":_vm.selectLists.countries,"open-direction":"top","track-by":"countryIso","option-label":"name","errors":errors,"required":"","mapped":""},model:{value:(_vm.address.addressCountry),callback:function ($$v) {_vm.$set(_vm.address, "addressCountry", $$v)},expression:"address.addressCountry"}})]}}],null,true)})],1),_c('p',{staticClass:"mt-2 text-xs text-gray-700 col-span-full"},[_vm._v("* Pflichtfelder")]),_c('last-changed-by',{attrs:{"updated-at":_vm.address.updatedAt,"updated-by":_vm.address.updatedBy}}),_c('div',{staticClass:"col-span-full flex h-9"},[_c('e-button',{staticClass:"ml-auto mt-18",attrs:{"variant":"primary","disabled":invalid,"data-test":"profile__address-edit-save"},on:{"click":_vm.saveAddress}},[_c('e-icon',{staticClass:"mr-2",attrs:{"icon":"mdi-content-save-outline"}}),_vm._v(" Speichern")],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }