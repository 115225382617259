<template>
  <div class="search-wrapper">
    <e-toolbar v-show="false">
      <e-button disabled variant="tertiary" small>...</e-button>
    </e-toolbar>
    <error-banner />
    <searchable-list
      disable-selection
      hide-select-all
      hide-pagination-limit
      :has-searched="isValidSearchQuery"
      :image-src="imageSrc"
      :is-loading="isLoading"
      :pagination="pagination"
      :results="results"
      :search-fields="searchFields"
      :extended-search-fields="extendedSearchFields"
      @search="submitSearch"
      @select-all="selectAll"
    >
      <template #search-result="{ result }">
        <e-card
          :id="`search-result-${result.id}`"
          sheet
          rounded
          elevation="sm"
          :to="{ name: 'profile', params: { id: result.id } }"
          hover
          data-test="search-result"
        >
          <template #sheet>
            <search-result
              :is-selected="selected.includes(result.id)"
              :result="result"
              @toggle-selection="toggleSelect"
            />
          </template>
        </e-card>
      </template>
    </searchable-list>
  </div>
</template>

<script>
import { ECard, EToolbar, EButton } from '@careerpartner/nitro';
import SearchableList from '@/components/Search/SearchableList';
import { ApiServiceWrapper } from '@/utils/apiServiceWrapper';
import { stringify as _stringify } from 'qs';
import { API_ENDPOINTS, PAGE_TITLE_TEMPLATE } from '@/common/constants';
import { Actions as AppActions } from '@/store/modules/app/types';
import { Mutations as ProfileMutations } from '@/store/modules/profile/types';
import ErrorBanner from '@/components/ErrorBanner';
import SearchResult from '@/components/Search/SearchResult';
import { imagePaths } from '@/common/content';

export default {
  name: 'CustomerSearch',
  metaInfo() {
    return {
      title: `Suche`,
      titleTemplate: `%s ${PAGE_TITLE_TEMPLATE}`,
    };
  },
  components: {
    SearchResult,
    EButton,
    ECard,
    ErrorBanner,
    EToolbar,
    SearchableList,
  },
  data() {
    return {
      isLoading: false,
      results: [],
      selected: [],
      queryObject: {
        filter: {},
      },
      pagination: {
        limit: 20,
        offset: 0,
      },
      dateOfBirthValue: '',
    };
  },
  computed: {
    imageSrc() {
      return imagePaths.SEARCH_IMAGE_URL;
    },
    searchFields() {
      return [
        {
          queryParam: 'name',
          label: 'Vollständiger Name',
          value: this.$route.query.name,
          dataTest: 'search-name',
        },
        {
          queryParam: 'matriculationNumber',
          label: 'Matrikelnummer',
          value: this.$route.query.matriculationNumber,
          dataTest: 'search-matriculation-number',
        },
      ];
    },
    extendedSearchFields() {
      return [
        {
          queryParam: 'email',
          label: 'E-Mail-Adresse',
          value: this.$route.query.email,
          dataTest: 'search-email',
        },
        {
          queryParam: 'dateOfBirth',
          label: 'Geburtstag',
          value: this.$route.query.dateOfBirth,
          dataTest: 'search-dateOfBirth-date-input',
          type: 'date',
        },
      ];
    },
    isValidSearchQuery() {
      return Object.values(this.$route.query).some((param) => !!param);
    },
  },
  created() {
    this.$store.commit(
      `profile/${ProfileMutations.SET_HAS_FETCHED_PROFILE}`,
      false,
    );
  },
  mounted() {
    const { limit, offset, totalCount, ...query } = this.$route.query;

    if (!this.isValidSearchQuery || !query) return;

    this.submitSearch({ query, limit, offset });
  },
  methods: {
    selectAll() {
      this.selected = this.selected.length
        ? []
        : this.results.map((item) => item.id);
    },
    isSelected(id) {
      return this.selected.includes(id);
    },
    async submitSearch({ query, limit, offset }) {
      this.isLoading = true;

      if (query) {
        this.queryObject.filter = {};
        this.queryObject.filter = query;
      }

      const queryString = _stringify(this.queryObject, { encode: true });

      const apiQuery = `${API_ENDPOINTS.CUSTOMER_PROFILES}?${queryString}&offset=${offset}&limit=${limit}`;

      try {
        const { data } = await ApiServiceWrapper.get(apiQuery);

        this.results = data.results;
        this.pagination = data.pagination;
        const { limit, offset } = data.pagination;
        this.selected = [];

        await this.$router.push({
          ...this.$route,
          query: { ...this.queryObject.filter, limit, offset },
        });
      } catch (e) {
        await this.$store.dispatch(`app/${AppActions.SET_ERROR}`, e);
      } finally {
        this.isLoading = false;
        this.$nextTick(() => {
          if (this.results.length) {
            document
              .querySelector(`#search-result-${this.results[0].id}`)
              .focus();
          }
        });
      }
    },
    toggleSelect(id) {
      this.selected = this.selected.includes(id)
        ? this.selected.filter((itemId) => itemId !== id)
        : [...this.selected, id];
    },
  },
};
</script>
