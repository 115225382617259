<template>
  <div v-if="loading">
    <loader />
  </div>
  <div v-else class="statistics-wrapper">
    <validation-observer
      ref="statistics-form"
      v-slot="{ errors, valid, dirty }"
      slim
      tag="div"
    >
      <e-toolbar>
        <e-button
          :disabled="!valid || !dirty || waitingForResponse"
          variant="tertiary"
          small
          data-test="profile-save-all"
          @click="updateStatistics"
        >
          <e-icon
            v-if="waitingForResponse"
            class="mr-2 animate-spin"
            icon="mdi-loading"
          />
          <e-icon
            v-else
            icon="mdi-content-save-outline"
            class="mr-2"
          />Speichern</e-button
        >
      </e-toolbar>
      <error-banner />
      <div
        data-test="statistics-wrapper"
        class="flex flex-col xl:grid xl:grid-cols-2"
      >
        <div class="col-span-2">
          <term-statistic />
          <initial-academic-matriculation />
          <latest-academic-finals />
          <parallel-academic-studies />
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import InitialAcademicMatriculation from '../components/Statistic/InitialAcademicMatriculation';
import ParallelAcademicStudies from '../components/Statistic/ParallelAcademicStudies';
import TermStatistic from '../components/Statistic/TermStatistic';
import LatestAcademicFinals from '../components/Statistic/LatestAcademicFinals';
import ErrorBanner from '@/components/ErrorBanner';
import { ValidationObserver } from 'vee-validate';
import { mapGetters } from 'vuex';
import {
  Actions as AppActions,
  Getters as AppGetters,
} from '@/store/modules/app/types';
import { Getters as ProfileGetters } from '@/store/modules/profile/types';
import { Actions as AcademicHistoryActions } from '@/store/modules/academicHistory/types';
import { EButton, EIcon, EToolbar } from '@careerpartner/nitro';
import Loader from '@/components/Loader';
import fetchProfile from '@/mixins/fetchProfile';
import { PAGE_TITLE_TEMPLATE } from '@/common/constants';

export default {
  name: 'Statistics',
  metaInfo() {
    return {
      title: `${this.profileName} - Statistik`,
      titleTemplate: `%s ${PAGE_TITLE_TEMPLATE}`,
    };
  },
  components: {
    Loader,
    EToolbar,
    EButton,
    EIcon,
    ErrorBanner,
    LatestAcademicFinals,
    TermStatistic,
    InitialAcademicMatriculation,
    ParallelAcademicStudies,
    ValidationObserver,
  },
  mixins: [fetchProfile],
  data() {
    return {
      waitingForResponse: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: `app/${AppGetters.LOADING}`,
      hasErrors: `app/${AppGetters.HAS_ERRORS}`,
      profileName: `profile/${ProfileGetters.FULL_NAME}`,
    }),
  },
  methods: {
    async updateStatistics() {
      this.waitingForResponse = true;

      try {
        await this.$store.dispatch(
          `academicHistory/${AcademicHistoryActions.UPDATE_ACADEMIC_HISTORY}`,
        );
        this.$refs['statistics-form'].reset();
      } catch (e) {
        await this.$store.dispatch(`app/${AppActions.SET_ERROR}`, error, {
          root: true,
        });
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
