import '@/styles/main.scss';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import sentryInit, { Sentry } from './plugins/sentry';
import VueMeta from 'vue-meta';
import './config/set-public-path';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import '@careerpartner/nitro/dist/styles.css';
import './assets/tailwind.css';
import { loadEnabledFeatures } from './utils/feature-config';

Vue.config.productionTip = false;

Vue.config.errorHandler = (error) => {
  Sentry.captureException(error);
  throw error;
};

const containerSelector = '#app-placeholder';
Vue.use(VueMeta);

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    store,
    render(h) {
      return h(App);
    },
    el: containerSelector,
  },
});

export async function mount(props) {
  sentryInit();
  await loadEnabledFeatures();
  await vueLifecycles.mount(props);
}

export const bootstrap = vueLifecycles.bootstrap;
export const unmount = vueLifecycles.unmount;

export const devtools = {
  overlays: {
    selectors: [containerSelector],
  },
};
