<template>
  <div v-if="loading">
    <loader />
  </div>
  <div v-else class="profile-wrapper">
    <validation-observer
      ref="profile-form"
      v-slot="{ valid, dirty }"
      slim
      tag="div"
    >
      <e-toolbar>
        <e-button
          :disabled="!valid || !dirty || waitingForResponse"
          variant="tertiary"
          small
          data-test="profile-save-all"
          @click="saveProfile"
        >
          <e-icon
            v-if="waitingForResponse"
            class="mr-2 animate-spin"
            icon="mdi-loading"
          />
          <e-icon v-else icon="mdi-content-save-outline" class="mr-2" />
          Speichern
        </e-button>
      </e-toolbar>
      <error-banner />
      <div
        data-test="profile-wrapper"
        class="flex flex-col xl:grid xl:grid-cols-2 xl:gap-x-12"
      >
        <div class="col-span-1">
          <general-info />
          <personal-info />
          <university-entrance-qualification />
        </div>
        <div class="col-span-1">
          <contact-info />
        </div>
        <div class="col-span-2">
          <health-insurance />
        </div>
        <div class="col-span-1">
          <identity-document />
          <residence-permit />
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  Actions as AppActions,
  Getters as AppGetters,
} from '@/store/modules/app/types';
import {
  Actions as ProfileActions,
  Getters as ProfileGetters,
} from '@/store/modules/profile/types';
import { mapGetters } from 'vuex';
import { EToolbar, EButton, EIcon } from '@careerpartner/nitro';
import { ValidationObserver } from 'vee-validate';
import ErrorBanner from '@/components/ErrorBanner';
import GeneralInfo from '@/components/Profile/GeneralInfo';
import PersonalInfo from '@/components/Profile/PersonalInfo';
import HealthInsurance from '@/components/HealthInsurance/Display';
import UniversityEntranceQualification from '@/components/Profile/UniversityEntranceQualification';
import IdentityDocument from '@/components/Profile/IdentityDocument';
import ResidencePermit from '@/components/Profile/ResidencePermit';
import ContactInfo from '@/components/Profile/ContactInfo';
import Loader from '@/components/Loader';
import fetchProfile from '@/mixins/fetchProfile';
import { PAGE_TITLE_TEMPLATE } from '@/common/constants';

export default {
  name: 'Profile',
  metaInfo() {
    return {
      title: `${this.profileName} - Profil`,
      titleTemplate: `%s ${PAGE_TITLE_TEMPLATE}`,
    };
  },
  components: {
    Loader,
    ContactInfo,
    EButton,
    EIcon,
    ErrorBanner,
    EToolbar,
    GeneralInfo,
    HealthInsurance,
    IdentityDocument,
    PersonalInfo,
    ResidencePermit,
    UniversityEntranceQualification,
    ValidationObserver,
  },
  mixins: [fetchProfile],
  data() {
    return {
      isValidForSave: false,
      validateStoreSubscriber: null,
      waitingForResponse: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: `app/${AppGetters.LOADING}`,
      hasErrors: `app/${AppGetters.HAS_ERRORS}`,
      profileName: `profile/${ProfileGetters.FULL_NAME}`,
    }),
  },
  methods: {
    async saveProfile() {
      this.waitingForResponse = true;

      try {
        await this.$store.dispatch(
          `profile/${ProfileActions.SAVE_ALL_CHANGES}`,
        );
        this.$refs['profile-form'].reset();
      } catch (e) {
        await this.$store.dispatch(`app/${AppActions.SET_ERROR}`, error);
      } finally {
        this.waitingForResponse = false;
      }
    },
    formatValidationMessages(errors) {
      return Object.entries(errors)
        .filter(([_key, value]) => value.length)
        .map(([key, value]) => `${key}: ${value[0]}`);
    },
  },
};
</script>
