import { Getters } from './types';
import { getField } from 'vuex-map-fields';

function sortContactsByPrimary(contactPoints) {
  return [...contactPoints].sort((pointA, _pointB) =>
    ['shipping', 'primary'].some((el) => pointA?.usageType?.includes(el))
      ? -1
      : 1,
  );
}

export default {
  getField,
  [Getters.FULL_NAME](state) {
    const birthname = state.person.birthName
      ? `(${state.person.birthName})`
      : '';

    const givenName = state.person.givenName || ' ';
    const familyName = state.person.familyName || ' ';

    return `${givenName} ${familyName} ${birthname}`;
  },
  [Getters.PROFILE_INITIALS](state) {
    if (!state.person.givenName && !state.person.familyName) {
      return 'NN';
    }

    const initials = `${state.person.givenName.charAt(0) || ' '}${
      state.person.familyName.charAt(0) || ' '
    }`;
    return initials.toUpperCase();
  },
  [Getters.PROFILE_ID](state) {
    return state.id;
  },
  [Getters.PROFILE_IMAGE_URL](state) {
    return state.imageUrl;
  },
  [Getters.PROFILE](state) {
    return state;
  },
  [Getters.PROFILE_ADDRESSES](state) {
    return sortContactsByPrimary(state.address);
  },
  [Getters.PROFILE_EMAILS](state) {
    return sortContactsByPrimary(state.email);
  },
  [Getters.PROFILE_PHONES](state) {
    return sortContactsByPrimary(state.phone);
  },
  [Getters.PROFILE_BOOKING](state) {
    return {
      academyId: state.academyId,
      matriculationNumber: state.matriculationNumber,
      campus: state.campus,
    };
  },
  [Getters.PROFILE_DAM_FOLDER_URL](state) {
    return state.damFolderUrl;
  },
  [Getters.HAS_CERTIFIED_HZB](state) {
    return (
      Object.keys(state.universityEntranceQualification).includes(
        'certifiedOn',
      ) && state.universityEntranceQualification.certifiedOn !== null
    );
  },
  [Getters.INSURANCE_PROFILE_ID](state) {
    return state.healthInsurance.id;
  },
  [Getters.INSURANCE_MESSAGES_PAGINATION](state) {
    return state.healthInsuranceMessages.pagination;
  },
};
