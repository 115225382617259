var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-structure',{attrs:{"has-image":"","image-src":_vm.previousStudiesImageURL},scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v("Studienzeiten an Hochschulen in Deutschland ")]},proxy:true}])},[_c('div',{staticClass:"col-span-full"},[_c('p',[_vm._v(" Gesamtanzahl aller Hochschulsemester: "),_c('strong',{staticClass:"text-cta",attrs:{"data-test":"statistics__totalTerms"}},[_vm._v(_vm._s(_vm.totalTerms || '0'))]),_c('br'),_c('span',{staticClass:"text-gray-700 text-italic"},[_vm._v("ohne Unterbrechungssemester/Studienkolleg")])])]),_c('div',{staticClass:"col-span-full flex space-x-2"},[_c('validation-provider',{attrs:{"name":"Term Statistic | Anzahl reguläre Hochschulsemester","rules":_vm.provideValidationRules('@regularTerms'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"previous-terms","data-test":"statistics__regularTerms","label":"Anzahl reguläre Hochschulsemester","errors":errors,"wide":""},model:{value:(_vm.regularTerms),callback:function ($$v) {_vm.regularTerms=$$v},expression:"regularTerms"}})]}}])}),_c('e-tooltip',{staticClass:"items-center justify-center text-cta",attrs:{"content":"Zu regulären Hochschulsemester gehören alle Semester außer Urlaubs-, Praxis-, Unterbrechungs-semester und Semester am Studienkolleg."}},[_c('e-icon',{attrs:{"icon":"mdi-information-outline","size":"2xl"}})],1)],1),_c('div',{staticClass:"col-span-full flex space-x-2"},[_c('validation-provider',{attrs:{"name":"Term Statistic | Anzahl Urlaubssemester","rules":_vm.provideValidationRules('@holidayTerms'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"previous-holiday-terms","data-test":"statistics__holidayTerms","label":"Anzahl Urlaubssemester","errors":errors,"wide":""},model:{value:(_vm.holidayTerms),callback:function ($$v) {_vm.holidayTerms=$$v},expression:"holidayTerms"}})]}}])}),_c('e-tooltip',{staticClass:"items-center justify-center text-cta",attrs:{"content":"Bei einem Urlaubssemester handelt es sich um die offizielle, Unterbrechung des Studiums für ein Semester.  Der Studierendenstatus bleibt dabei erhalten."}},[_c('e-icon',{attrs:{"icon":"mdi-information-outline","size":"2xl"}})],1)],1),_c('div',{staticClass:"col-span-full flex space-x-2"},[_c('validation-provider',{attrs:{"name":"Term Statistic | Anzahl Praxissemester","rules":_vm.provideValidationRules('@practicalTerms'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"previous-practical-terms","data-test":"statistics__practicalTerms","label":"Anzahl Praxissemester","errors":errors,"wide":""},model:{value:(_vm.practicalTerms),callback:function ($$v) {_vm.practicalTerms=$$v},expression:"practicalTerms"}})]}}])}),_c('e-tooltip',{staticClass:"items-center justify-center text-cta",attrs:{"content":"Fachpraktische Studiensemester an deutschen Hochschulen bei fortbestehender Einschreibung an der Hochschule."}},[_c('e-icon',{attrs:{"icon":"mdi-information-outline","size":"2xl"}})],1)],1),_c('div',{staticClass:"col-span-full flex space-x-2"},[_c('validation-provider',{attrs:{"name":"Term Statistic | Semester am Studienkolleg","rules":_vm.provideValidationRules('@preparatoryTerms'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"previous-preparatory-terms","data-test":"statistics__preparatoryTerms","label":"Semester am Studienkolleg","errors":errors,"wide":""},model:{value:(_vm.preparatoryTerms),callback:function ($$v) {_vm.preparatoryTerms=$$v},expression:"preparatoryTerms"}})]}}])}),_c('e-tooltip',{staticClass:"items-center justify-center text-cta",attrs:{"content":"Getrennte Semesterzählung. (Die am Studienkolleg verbrachten Semester zählen nicht als Fach- oder Hochschulsemester). Auf Studienkollegs werden Studienbewerber mit einer ausländischen HZB, die nicht als dem deutschen Abitur gleichwertig anerkannt ist, auf ein Studium an einer deutschen Hochschule vorbereitet."}},[_c('e-icon',{attrs:{"icon":"mdi-information-outline","size":"2xl"}})],1)],1),_c('div',{staticClass:"col-span-full flex space-x-2"},[_c('validation-provider',{attrs:{"name":"Term Statistic | Anzahl Unterbrechungssemester","rules":_vm.provideValidationRules('@interruptionTerms'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"previous-interruption-terms","data-test":"statistics__interruptionTerms","label":"Anzahl Unterbrechungssemester","errors":errors,"wide":""},model:{value:(_vm.interruptionTerms),callback:function ($$v) {_vm.interruptionTerms=$$v},expression:"interruptionTerms"}})]}}])}),_c('e-tooltip',{staticClass:"items-center justify-center text-cta",attrs:{"content":"Eine Studienunterbrechung liegt vor, wenn nach Aufnahme des Studiums in Deutschland für ein oder mehrere Semester keine Einschreibung im 1. Studiengang des Berichtssemesters erfolgt ist."}},[_c('e-icon',{attrs:{"icon":"mdi-information-outline","size":"2xl"}})],1)],1),(_vm.hasInterruptionTerms)?_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{attrs:{"name":"Term Statistic | Arten der Studienunterbrechung","rules":"required","immediate":"","skip-if-empty":false,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"previous-interruption-type","data-test":"statistics__interruptionType","options":_vm.selectLists.interruptions,"track-by":"statisticKey","option-label":"label","errors":errors,"label":"Arten der Studienunterbrechung (Mehrfachauswahl)","multiple":"","disabled":_vm.hasNoInterruptionTerms,"mapped":""},model:{value:(_vm.interruptionType),callback:function ($$v) {_vm.interruptionType=$$v},expression:"interruptionType"}})]}}],null,false,1814891788)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }