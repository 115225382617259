(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@epos/core-applets"));
	else if(typeof define === 'function' && define.amd)
		define(["@epos/core-applets"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@epos/core-applets")) : factory(root["@epos/core-applets"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE_d684__) {
return 