<template>
  <e-applet-wrapper id="epos-academic-profile" name="EPOS Customer Profile">
    <template #viewnavigation>
      <e-view-navigation :links="links">
        <template #headline>
          {{ viewNavigationLabel.headline }}
        </template>
        <template #subheadline>
          {{ viewNavigationLabel.subHeadline }}
        </template>
      </e-view-navigation>
    </template>
    <template #appletcontent>
      <router-view />
      <Snackbar />
    </template>
  </e-applet-wrapper>
</template>

<script>
import { makeCustomerServiceTabs } from '@epos/core-applets';
import {
  Actions as AppActions,
  Getters as AppGetters,
  Mutations as AppMutations,
} from '@/store/modules/app/types';
import { Getters as ProfileGetters } from '@/store/modules/profile/types';
import { mapGetters } from 'vuex';
import Snackbar from './components/Snackbar.vue';
import { EAppletWrapper, EViewNavigation } from '@careerpartner/nitro';
import Store from '@/store';

export default {
  name: 'AppAcademicProfile',
  components: { EAppletWrapper, EViewNavigation, Snackbar },
  computed: {
    ...mapGetters({
      profileName: `profile/${ProfileGetters.FULL_NAME}`,
      damFolderUrl: `profile/${ProfileGetters.PROFILE_DAM_FOLDER_URL}`,
    }),
    viewNavigationLabel() {
      return {
        headline: this.$route.meta.headline || '...',
        subHeadline: this.$route.meta.subheadline || this.profileName || '...',
      };
    },
    links() {
      return makeCustomerServiceTabs(this.$route.params.id, this.damFolderUrl);
    },
  },
  async created() {
    await Store.dispatch(`app/${AppActions.DISMISS_ERROR}`);

    if (!Store.getters[AppGetters.SELECT_LISTS]) {
      await Store.dispatch(`app/${AppActions.LOAD_SELECT_LISTS}`);
    }
  },
  beforeDestroy() {
    this.$store.commit(`app/${AppMutations.CLEAR_ERRORS}`);
  },
};
</script>

<!--
  Calendar icon picker not shown in chrome, this is done via ETextField, overwrite it here for the whole app
  Referenced ticket: https://careerpartner.atlassian.net/browse/EP-11477
-->
<style>
input[type='date']::-webkit-calendar-picker-indicator {
  display: initial;
  appearance: initial;
}
</style>
