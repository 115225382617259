var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"phone-form",attrs:{"vid":"phone-form","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"grid grid-cols-12 gap-x-6 gap-y-7 mt-9 px-4 pb-8",attrs:{"data-test":"profile__phone-modal"}},[_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Art der Telefonnummer","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"phone-contacttype","data-test":"profile__phone-edit-type","options":_vm.PHONE_TYPES,"errors":errors,"label":"Art der Telefonnummer","option-label":"label","track-by":"key","required":"","mapped":""},model:{value:(_vm.phone.contactType),callback:function ($$v) {_vm.$set(_vm.phone, "contactType", $$v)},expression:"phone.contactType"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-6"}),_c('div',{staticClass:"col-span-4"},[_c('validation-provider',{attrs:{"vid":"prefix","name":"Ländervorwahl mit +","rules":"required|phonePrefixWithPlus|phonePrefixAllowedEntry","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"phone-prefix","data-test":"profile__phone-edit-prefix","errors":errors,"required":"","label":"Ländervorwahl","wide":""},model:{value:(_vm.phone.prefix),callback:function ($$v) {_vm.$set(_vm.phone, "prefix", $$v)},expression:"phone.prefix"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-8"},[_c('validation-provider',{attrs:{"name":"Telefonnummer","slim":"","rules":{
          required: true,
          numeric: true,
          phoneValidator: {
            value: '@phone',
            prefix: '@prefix',
          },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"phone-number","data-test":"profile__phone-edit-phone","label":"Telefonnummer","wide":"","required":"","errors":errors},model:{value:(_vm.phone.phone),callback:function ($$v) {_vm.$set(_vm.phone, "phone", $$v)},expression:"phone.phone"}})]}}],null,true)})],1),_c('p',{staticClass:"mt-2 text-xs text-gray-700 col-span-full"},[_vm._v("* Pflichtfelder")]),_c('last-changed-by',{attrs:{"updated-at":_vm.phone.updatedAt,"updated-by":_vm.phone.updatedBy}}),_c('div',{staticClass:"col-span-full flex h-9"},[_c('e-button',{staticClass:"ml-auto mt-18",attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.savePhone}},[_c('e-icon',{staticClass:"mr-2",attrs:{"icon":"mdi-content-save-outline"}}),_vm._v(" Speichern")],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }