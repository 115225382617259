var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-structure',{scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v(" Persönliche Daten ")]},proxy:true}])},[_c('div',{staticClass:"col-span-3"},[_c('validation-provider',{attrs:{"name":"Personal Info | Geschlecht","rules":"required","slim":"","immediate":"","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"sexes","data-test":"profile__select-sex","options":_vm.selectLists.sexes,"errors":errors,"label":"Geschlecht","option-label":"label","track-by":"referenceId","mapped":"","required":""},model:{value:(_vm.sex),callback:function ($$v) {_vm.sex=$$v},expression:"sex"}})]}}])})],1),_c('div',{staticClass:"col-span-3 flex space-x-2"},[_c('validation-provider',{attrs:{"name":"Personal Info | Titel","slim":"","immediate":"","skip-if-empty":false}},[_c('e-multiselect',{attrs:{"id":"honorific-prefix","data-test":"profile__select-prefix","options":_vm.selectLists.honorificPrefixes,"label":"Titel","wide":"","mapped":"","option-label":"key","track-by":"key"},model:{value:(_vm.honorificPrefix),callback:function ($$v) {_vm.honorificPrefix=$$v},expression:"honorificPrefix"}})],1),_c('e-tooltip',{staticClass:"items-center justify-center text-cta",attrs:{"content":"Eine Bezeichnung, die man als Zusatz zu seinem Nachnamen verwendet und die einen akademischen Rang oder ein Amt bezeichnet."}},[_c('e-icon',{attrs:{"icon":"mdi-information-outline","size":"2xl"}})],1)],1),_c('div',{staticClass:"col-span-3"},[_c('validation-provider',{ref:"givenName",attrs:{"rules":"ruleForName|required|max:50","name":"Personal Info | Vorname","slim":"","immediate":"","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"given-name","data-test":"profile__given-name","errors":errors,"label":"Vorname"},model:{value:(_vm.givenName),callback:function ($$v) {_vm.givenName=$$v},expression:"givenName"}})]}}])})],1),_c('div',{staticClass:"col-span-3"},[_c('validation-provider',{ref:"familyName",attrs:{"rules":"ruleForName|required|max:50","name":"Personal Info | Nachname","slim":"","immediate":"","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"family-name","data-test":"profile__family-name","errors":errors,"label":"Nachname"},model:{value:(_vm.familyName),callback:function ($$v) {_vm.familyName=$$v},expression:"familyName"}})]}}])})],1),_c('div',{staticClass:"col-span-3"},[_c('validation-provider',{attrs:{"name":"Personal Info | Geburtsdatum","rules":{
        required: true,
        date_format: true,
        before: { date: new Date() },
      },"slim":"","immediate":"","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"birth-date","data-test":"profile__birth-date","errors":errors,"type":"date","label":"Geburtsdatum","required":""},model:{value:(_vm.birthDate),callback:function ($$v) {_vm.birthDate=$$v},expression:"birthDate"}})]}}])})],1),_c('div',{staticClass:"col-span-3"},[_c('validation-provider',{attrs:{"rules":"ruleForName|max:50","name":"Personal Info | Geburtsname","slim":"","immediate":"","skip-if-empty":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"birth-name","data-test":"profile__birth-name","errors":errors,"hide-details":"","label":"Geburtsname"},model:{value:(_vm.birthName),callback:function ($$v) {_vm.birthName=$$v},expression:"birthName"}})]}}])})],1),_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{attrs:{"name":"Personal Info | Staatsangehörigkeit","rules":"required","slim":"","immediate":"","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"nationality","data-test":"profile__select-nationality","errors":errors,"close-on-select":false,"options":_vm.selectLists.countries,"searchable":"","label":"Staatsangehörigkeit","option-label":"name","track-by":"countryIso","multiple":"","mapped":"","required":"","max":3},model:{value:(_vm.nationality),callback:function ($$v) {_vm.nationality=$$v},expression:"nationality"}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }