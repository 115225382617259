var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-structure',{attrs:{"is-extendable":"","mount-closed":""},scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v(" Ausweisdokumente (Nur erforderlich für CS Studierende) ")]},proxy:true}])},[_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{attrs:{"name":"IdentityDocument | Typ"}},[_c('e-radio-group',{attrs:{"name":"documentType","label":"Art des Ausweisdokuments","data-test":"profile__id-type","options":[
          {
            label: 'Reisepass',
            value: 'passport',
          },
          {
            label: 'Personalausweis (bei deutschen Studierenden)',
            value: 'idCard',
          } ]},model:{value:(_vm.documentType),callback:function ($$v) {_vm.documentType=$$v},expression:"documentType"}})],1)],1),_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{attrs:{"name":"IdentityDocument | Name","rules":"noDigits|ruleForName|max:100","immediate":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"documentHolder","data-test":"profile__id-documentHolder","errors":errors,"label":"Vollständiger Name auf Ausweisdokument"},model:{value:(_vm.documentHolder),callback:function ($$v) {_vm.documentHolder=$$v},expression:"documentHolder"}})]}}])})],1),_c('div',{staticClass:"col-span-3 flex space-x-2"},[_c('validation-provider',{attrs:{"name":"IdentityDocument | Id","rules":"identityDocumentId","skip-if-empty":true,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"documentId","data-test":"profile__id-documentId","errors":errors,"label":"Ausweisnummer - 9 stellig","wide":""},model:{value:(_vm.documentId),callback:function ($$v) {_vm.documentId=$$v},expression:"documentId"}})]}}])})],1),_c('div',{staticClass:"col-span-3"},[_c('validation-provider',{attrs:{"name":"IdentityDocument | Ablaufdatum","rules":_vm.dateValidator,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"documentValid","type":"date","data-test":"profile__id-validUntil","label":"Gültig bis","errors":errors},model:{value:(_vm.validUntil),callback:function ($$v) {_vm.validUntil=$$v},expression:"validUntil"}})]}}])})],1),_c('div',{staticClass:"col-span-3 flex space-x-2"},[_c('validation-provider',{attrs:{"name":"IdentityDocument | Ankunftsdatum","rules":{ date_format: true, before: { date: _vm.tomorrowDate } },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"arrival-campus","type":"date","errors":errors,"data-test":"profile__id-arrivalOnCampus","label":"Angereist am Campus","wide":"","max-date":_vm.todayDayDate},model:{value:(_vm.arrivalOnCampus),callback:function ($$v) {_vm.arrivalOnCampus=$$v},expression:"arrivalOnCampus"}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }