var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-structure',{attrs:{"has-image":"","image-src":_vm.graduatedStudyImageURL},scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v("Letzte Abschlussprüfung")]},proxy:true}])},[_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{attrs:{"name":"Latest Academic Finals | Hochschule der letzten Abschlussprüfung","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"graduated-university","errors":errors,"data-test":"statistics__latest-university","options":_vm.selectLists.universities,"track-by":"statisticKey","option-label":"label","label":"Hochschule der letzten Abschlussprüfung","searchable":"","mapped":""},model:{value:(_vm.universityCode),callback:function ($$v) {_vm.universityCode=$$v},expression:"universityCode"}})]}}])})],1),(_vm.isNonGermanUniversity)?_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{attrs:{"name":"Latest Academic Finals | Hochschule der letzten Abschlussprüfung","slim":"","rules":"required","skip-if-empty":false,"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"graduated-country","data-test":"statistics__latest-country","errors":errors,"options":_vm.countriesWithoutGermany,"track-by":"statisticKey","option-label":"label","label":"Land","searchable":"","mapped":""},model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}})]}}],null,false,611510688)})],1):_vm._e(),_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{attrs:{"name":"Latest Academic Finals | Art der Abschlussprüfung","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"graduated-examination","errors":errors,"data-test":"statistics__latest-type","options":_vm.selectLists.graduationTypes,"track-by":"statisticKey","option-label":"label","label":"Art der Abschlussprüfung","searchable":"","mapped":""},model:{value:(_vm.examinationType),callback:function ($$v) {_vm.examinationType=$$v},expression:"examinationType"}})]}}])})],1),_c('div',{staticClass:"col-span-full flex space-x-2"},[_c('validation-provider',{attrs:{"name":"Latest Academic Finals | Studienfach","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"graduated-course","errors":errors,"data-test":"statistics__latest-course","options":_vm.selectLists.studyCourses,"track-by":"statisticKey","option-label":"label","label":"Studienfach","searchable":"","mapped":""},model:{value:(_vm.studyCourse),callback:function ($$v) {_vm.studyCourse=$$v},expression:"studyCourse"}})]}}])}),_c('e-tooltip',{staticClass:"items-center justify-center text-cta",attrs:{"content":"Falls das gewünschte Studienfach nicht auffindbar ist, sollte eine möglichst inhaltsnahe Zuordnung erfolgen. So würde man z.B. “Sporttourismus”  zu “Tourismuswirtschaft” zuordnen."}},[_c('e-icon',{attrs:{"icon":"mdi-information-outline","size":"2xl"}})],1)],1),_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{ref:"graduated-year",attrs:{"name":"Monat und Jahr der letzten Prüfung"}},[_c('e-datepicker',{attrs:{"id":"graduated-year","data-test":"statistics__latest-monthyear","label":"Monat und Jahr der letzten Prüfung","picker-type":"month","format":"MM/YYYY","max-date":new Date().toISOString()},model:{value:(_vm.parseGraduationDate),callback:function ($$v) {_vm.parseGraduationDate=$$v},expression:"parseGraduationDate"}})],1)],1),_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{attrs:{"name":"Latest Academic Finals | Prüfungsergebnis"}},[_c('e-radio-group',{attrs:{"name":"graduated-pass","label":"Prüfungsergebnis","data-test":"statistics__latest-pass","options":[
          { value: '01', label: 'bestanden' },
          { value: '02', label: 'nicht bestanden' } ]},model:{value:(_vm.pass),callback:function ($$v) {_vm.pass=$$v},expression:"pass"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }